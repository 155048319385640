<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  /**
   * preload here data need in other components
   */
  created() {
    this.$store.commit('eventModule/RESET_STATE');
  }
}
</script>

